import React, { useState } from 'react';
import './Pricing.css';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import { EmojiPeople, MonetizationOn } from '@mui/icons-material'; // Icons for Free and Pro


function Pricing() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(false);

  const handleProClick = () => {
    fetch('https://parseapi.back4app.com/functions/insertProClick', {
      method: 'POST',
      headers: {
        'X-Parse-Application-Id': '3p0gg6JiJlhIElzLlhah14yVU5A3ftfsfMrervFd',
        'X-Parse-REST-API-Key': 'uABn0ZMKt192Tvbx5ywNQpBYCPydjc8yo98jNv47',
      },
    }).catch((error) => {
      console.error('Error calling Pro Click API:', error);
    });
  };

  const handleSubscribeMail = (email) => {
    fetch('https://parseapi.back4app.com/functions/subscribeMail', {
      method: 'POST',
      headers: {
        'X-Parse-Application-Id': '3p0gg6JiJlhIElzLlhah14yVU5A3ftfsfMrervFd',
        'X-Parse-REST-API-Key': 'uABn0ZMKt192Tvbx5ywNQpBYCPydjc8yo98jNv47',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mail: email, // Sending the email from the input field
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Subscription successful:', data);
      alert("Thank you! We'll keep you updated.");
    })
    .catch((error) => {
      console.error('Error subscribing:', error);
      alert("There was an issue. Please try again.");
    });
  };
  

  // Function to open the modal
  const openModal = () => {
    handleProClick();
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
    setEmail('');
    setEmailValid(false);
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    const input = e.target.value;
    setEmail(input);
    setEmailValid(validateEmail(input));
  };

  return (
    <div className="pricing-container">
      <h1>Pricing Plans</h1>
      <table className="pricing-table">
      <thead>
          <tr>
            <th></th>
            <th><EmojiPeople sx={{ fontSize: 40, color: '#4caf50' }} /></th> {/* Person icon for the Free plan */}
            <th><MonetizationOn sx={{ fontSize: 40, color: '#9e65e0' }} /></th> {/* Office building icon for the Pro plan */}
          </tr>
        </thead>


        <tbody>
          <tr>
            <td></td>
            <td>No registration, No charge</td>
            <td><strong>USD 9 per month</strong></td>
          </tr>
          <tr>
            <td>Boards</td>
            <td>Unlimited number of boards with 28 days time to live (boards are deleted afterwards)</td>
            <td>Unlimited number of boards with 90 days time to archive</td>
          </tr>
          <tr>
            <td>Share boards with team</td>
            <td>Anyone with link can contribute to the board</td>
            <td>Anyone with link can contribute to the board</td>
          </tr>
          <tr>
            <td>Managing Boards</td>
            <td>n/a</td>
            <td>List your boards, Remove cards, Change board URL, Remove board, Rename board</td>
          </tr>
          <tr>
            <td>Archived Boards</td>
            <td>n/a</td>
            <td>The owner is able to view archived boards</td>
          </tr>
          <tr>
            <td>Export Board Data</td>
            <td>n/a</td>
            <td>Export available</td>
          </tr>
          <tr>
            <td>Customer Support</td>
            <td>n/a</td>
            <td>Support available</td>
          </tr>
          <tr>
            <td></td>
            <td>
            <a href="https://app.linktoretro.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Button 
                  sx={{
                    backgroundColor: 'white', /* Purple background */
                    color: '#9e65e0',               /* White text */
                    '&:hover': {
                      backgroundColor: '#d3d3d3' /* Darker purple on hover */
                    }
                  }}
                >
                  Try for free
                </Button>
            </a>
            </td>
            <td>
              <Button 
                sx={{
                  backgroundColor: '#9e65e0', /* Purple background */
                  color: '#fff',               /* White text */
                  '&:hover': {
                    backgroundColor: '#8752c3' /* Darker purple on hover */
                  }
                }} 
                onClick={openModal}
              >
                <strong>GET PRO NOW</strong>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Modal using MUI with same styling */}
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Uh. Oh. We are not there yet.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We are working hard on adding the Pro Account features. Please give us bit more time. 
            Submit your e-mail and we will keep you posted!
          </DialogContentText>
          <br></br>
          <TextField
            fullWidth
            margin="dense"
            label="Enter your email"
            type="email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            error={!isEmailValid && email !== ''}
            helperText={!isEmailValid && email !== '' ? 'Enter a valid email' : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',
                },
                '&:hover fieldset': {
                  borderColor: 'black',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'grey',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'grey',
              },
            }}
          />
          <DialogContentText fontSize={"12px"}>
          By submitting your e-mail you agree to our &nbsp; 
            <a href="/t-c" target="_blank" rel="noopener noreferrer" style={{ color: '#bb86fc', textDecoration: 'none' }}>
                 Terms & Conditions and Privacy Policy
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} sx={{ color: '#bb86fc' }}>OK</Button>
          <Button 
              onClick={() => {
                if (isEmailValid) {
                  handleSubscribeMail(email);  // Call the API with the entered email
                  closeModal();  // Close the modal after successful API call
                }
              }} 
              disabled={!isEmailValid} 
              variant="contained" 
              sx={{
                  backgroundColor: '#bb86fc',
                  '&:hover': {
                  backgroundColor: '#9c4dcc',
                  },
                  color: '#fff',
                  fontWeight: 'bold',
              }}
            >
              Keep me posted!
           </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Pricing;
