import './Home.css';
import Header from './Header';  // Import the Header component
import React, { useState, useEffect, useMemo } from 'react';

function Home() {
  const messages = useMemo(() => [
    "The easiest online tool for retrospective.",
    "Trusted by developers due to disabled light mode.",
    "Unlimited number of boards free of charge.",
    "Mobile responsive boards allows the team to add cards any time they need."
  ], []);

  const [currentMessage, setCurrentMessage] = useState('');
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [imageSrc, setImageSrc] = useState('/retro_board.png'); // Default image source

  const copyrightStyle = {
    width: '100%',
    textAlign: 'center',
    marginTop: '40px',
    fontSize: '12px',
    color: '#111111' // Darker color for copyright text
  };

  useEffect(() => {
    // Typing effect for each message
    if (charIndex < messages[messageIndex].length) {
      const timeout = setTimeout(() => {
        setCurrentMessage((prev) => prev + messages[messageIndex][charIndex]);
        setCharIndex(charIndex + 1);
      }, 20); // Adjust typing speed (ms)
      return () => clearTimeout(timeout);
    } else {
      // Once the message is complete, move to the next after a pause
      const timeout = setTimeout(() => {
        setCharIndex(0);
        setCurrentMessage('');
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length); // Loop through messages
      }, 2000); // Pause before moving to the next message
      return () => clearTimeout(timeout);
    }
  }, [charIndex, messageIndex, messages]);

  useEffect(() => {
    // Check screen width and set appropriate image
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageSrc('/retro_board_mobile.png'); // Set mobile image
      } else {
        setImageSrc('/retro_board.png'); // Set desktop image
      }
    };

    handleResize(); // Call on component mount
    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    };
  }, []);

  return (
    <div className="App">
      <Header /> {/* Add the header at the top */}

      <header className="App-header">
        <h1>Your space for continuous improvement and team collaboration designed for agile development teams.</h1>
        <p><strong>{currentMessage}</strong></p>
      </header>

      {/* Add Images of the Board */}
      <div className="board-image-container">
        <img src={imageSrc} alt="Retrospective Board" className="board-image" />
      </div>

      {/* Copyright notice at the bottom of the page */}
      <div style={copyrightStyle}>
        <p>
          © 2024 linktoretro.com, &nbsp;
          <a href="https://www.linkedin.com/in/pahavlicek/" target="_blank" rel="noopener noreferrer" style={{ color: '#424242' }}>
            Pavel Havlicek
          </a> 
          &nbsp;|&nbsp;
          <a href="/t-c" style={{ color: '#424242' }}>Terms & Conditions, Privacy Policy</a>
          &nbsp;This website does not use any cookies.
        </p>
      </div>
    </div>
  );
}

export default Home;
