import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Home from './Home'; 
import Pricing from './Pricing';  // Import the Pricing page
import TermsAndConditions from './TermsAndConditions';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} /> {/* Assuming you have a Home component */}
          <Route path="/pricing" element={<Pricing />} />  {/* Route for the Pricing page */}
          <Route path="t-c" element={<TermsAndConditions />} /> {/* Route for Terms & Conditions */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
