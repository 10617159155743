import React, { useState } from 'react';
import './Header.css';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (url) => {
    window.location.href = url; // Navigates to the specified URL
    handleClose();
  };

  return (
    <header className="header">
      <div className="header-content">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
          sx={{ color: '#bb86fc' }}
        >
          <MenuIcon />
        </IconButton>
        <a href="/" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <span className="header-title">linkto<strong>retro</strong></span>
        </a>
        <div className="use-it-now-button-container">
          <a href="https://app.linktoretro.com" target="_blank" rel="noopener noreferrer">
            <button className="use-it-now-button">USE IT NOW</button>
          </a>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick('/')}>
            Home
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('/pricing')}>
            Pricing
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
}

export default Header;
