import React from 'react';
import './TermsAndConditions.css'; // Add custom styles if necessary

function TermsAndConditions() {
  return (
    <div className="terms-container">
      <br></br>
      <br></br>
      <br></br>
      <h1>Terms & Conditions and Privacy Policy</h1>

      <h2>Terms & Conditions</h2>
      <p>Document dated: September 9, 2024</p>
      <p>
        This document outlines the Terms & Conditions ("T&Cs") under which Pavel Havlíček ("we", "us", "our") operates the agile retrospective board service ("the Service"), which is provided through our platform ("LinkToRetro"). These T&Cs apply to all users of the Service, including both free and pro tier users. By accessing or using our Service, you agree to be bound by these terms.
      </p>
      <p><strong>Service Description:</strong> LinkToRetro is an online tool for agile retrospective boards. Users can access a free tier and, when available, a pro tier subscription for $9 per month.</p>
      <p><strong>Acceptance of Terms:</strong> By creating an account or using the Service, you agree to comply with and be bound by these T&Cs. If you do not agree with these terms, you should discontinue using the Service.</p>
      <p><strong>Account Registration:</strong> To use our Service, you must register and provide a valid email address. You are responsible for maintaining the confidentiality of your account and password.</p>
      <p><strong>Fees and Payment:</strong></p>
      <p>
         - Free Tier: Our free tier offers limited access to the Service at no cost.<br />
         - Pro Tier: The pro tier, available for $9 per month, will include advanced features. Payment will be handled through a secure third-party service.<br />
         - Cancellation: You can cancel your subscription at any time, and no further charges will apply for future periods.<br />
      </p>
      <p><strong>Data Storage and Security:</strong> We store your email addresses for login purposes and communication regarding service updates, including the launch of the pro tier. Your data is stored securely with Back4App Inc, which adheres to privacy standards. We do not store or process any payment details directly.</p>
      <p><strong>Intellectual Property:</strong> All content and features available through LinkToRetro, including design, trademarks, and software, are owned by Pavel Havlíček. Unauthorized use of any material is prohibited.</p>
      <p><strong>Limitation of Liability:</strong> We are not liable for any damages or losses arising from your use or inability to use the Service, including service interruptions, data loss, or errors.</p>
      <p><strong>Termination:</strong> We may terminate or suspend access to our Service at any time without notice or liability, for any reason, including if you violate these T&Cs.</p>
      <p><strong>Governing Law:</strong> These terms are governed by the laws of the Czech Republic. Any disputes will be subject to the exclusive jurisdiction of courts in the Czech Republic.</p>
      <p><strong>Changes to These Terms:</strong> We reserve the right to update these T&Cs at any time. If significant changes are made, we will notify you by email.</p>

      <br />
      <br />
      <h2>Privacy Policy</h2>
      <p>Document dated: September 9, 2024</p>
      <p>
        This Privacy Policy explains how Pavel Havlíček ("we", "us", "our") collects, uses, and shares personal data through our service LinkToRetro. We are committed to protecting your privacy and handling your personal data responsibly.
      </p>
      <p><strong>Data Controller:</strong></p>
      <p>
        - Pavel Havlíček <br />
        - IČO: 07501668 <br />
        - Address: Záhřebská 562/41, Vinohrady, 12000 Praha 2 <br />
        - Email: linktoretro@gmail.com
      </p>
      <p><strong>Data We Collect:</strong> We only collect and store the following personal data: Email addresses for login and communication purposes.</p>
      <p><strong>How We Use Your Data:</strong> Your email address is used for login and to send service updates, such as notifications about the launch of the pro tier.</p>
      <p><strong>Legal Basis for Processing:</strong> Under the GDPR, our legal basis for processing email addresses is:</p>
      <p>
        - Contractual necessity: To provide access to the Service.<br />
        - Legitimate interest: To keep you informed about service updates.<br />
      </p>
      <p><strong>Data Storage and Security:</strong> Your email is securely stored with Back4App Inc. We take all reasonable measures to protect your data from unauthorized access.</p>
      <p><strong>Your Data Protection Rights:</strong> You have the right to:</p>
      <p>
        - Access your data<br />
        - Request rectification of incorrect data<br />
        - Request deletion of your data<br />
        - Object to receiving service updates<br />
      </p>
      <p>To exercise any of these rights, contact us at linktoretro@gmail.com.</p>
      <p><strong>Data Retention:</strong> We will retain your email address for as long as your account is active or until you request its deletion.</p>
      <p><strong>Changes to This Privacy Policy:</strong> We may update this Privacy Policy from time to time. Any significant changes will be communicated via email.</p>


      <br />
      <br />
      <h2>Cookies Policy</h2>
      <p>This website does not use any cookies.</p>
    </div>
  );
}

export default TermsAndConditions;
